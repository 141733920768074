<template>
    <div class="download-page-pc">
        <div class="page">
            <img src="./images/pc/bg-pc.png" class="bg-img">
            <div class="page-content">
                <div class="page-content-left">
                    <div class="page-content-title">
                        智慧课堂-PC客户端
                    </div>
                    <div class="page-content-intr">
                        不改变教师原有习惯，提供互动课本及多种特色教学工具，辅助教师高效教学
                    </div>
                    <div class="page-content-btn">
                        <div class="windows-btn btn-item" @click="download('iclass')">
                            <span class="icon"></span>
                            <div>
                                <p>Windows<span style="font-size:20px;">版下载</span></p>
                                <span class="tip">（支持win7及以上）</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-content-right">
                    <img src="./images/pc/pc_img1.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixins from './utils.js';
export default {
    name: 'DownloadPc',
    mixins: [mixins],
};
</script>
<style lang="scss" scoped>
 @import "./common.scss";
.download-page-pc{
    height: 100%;
    width: 100%;
    .page{
        // background-image: url(./images/pc/bg-pc.png);
        .page-content-btn{
            width: 300px;
        }
        .windows-btn{
            width: 300px;
            .icon{
                width: 33px;
                height: 38px;
                margin-right: 19px;
                background-image: url(./images/windows.png);
            }

        }
    }
}
</style>
